import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getStructuresAction } from '../../../actions/structuresActions';
import DocumentsList from '../../../components/partials/DocumentsList/DocumentsList';
import styles from './Documents.module.scss';

export default function Documents() {
  const {
    lists, structuresList, files,
  } = useSelector((state) => state.structures);

  const { filters } = useSelector((state) => state.visualisation);

  const dispatch = useDispatch();

  const [structuresFiles, setStructuresFiles] = useState([]);

  const getData = useCallback(() => {
    getStructuresAction(dispatch);
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (structuresList?.length > 0 && filters?.structures.length > 0) {
      const filesList = structuresList.find(
        (s) => s._id === filters.structures[0],
      )?.files?.map((f) => ({
        file: f.file,
        type: f.type,
        structure: filters.structures[0],
      }));
      setStructuresFiles(filesList || []);
    }
  }, [structuresList, filters, files]);

  return (
    <div className={`page-content visualisation ${styles.container}`}>
      <div className={styles.lists}>
        {lists.documents.map((type) => <DocumentsList
          key={type}
          type={type}
          files={structuresFiles?.filter((f) => f.type === type)}
          />)}
      </div>
    </div>
  );
}
